import React from 'react'
import { graphql } from 'gatsby'
import Footer from '../components/footer'
import GoogleReviews from '../components/google_reviews'
import Nav from '../components/nav'
import Form from '../components/form'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from '../components/seo'

const ContactPage = ({data}) => {
  const entry = data.markdownRemark
  const image = getImage(entry.frontmatter.image)
	return (
    <div>
      <Nav />
      <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
      <div className="w-full pt-24 pb-24 lg:pt-24 lg:pb-24 bg-white relative">
        <div className="absolute -inset-0">
          <GatsbyImage image={image} alt={entry.frontmatter.heading} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
        </div>
        <div className="absolute -inset-0 bg-black opacity-25"></div>
        <div className="absolute -inset-0 opacity-1 bg-stardust"></div>
        <div className="w-6/12 mx-auto max-w-screen-xl relative">
          <div className="flex justify-around items-center text-center">
            <div>
              <h1 className="font-shoulders uppercase font-bold text-white text-6xl lg:text-8xl leading-none">{entry.frontmatter.heading}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-24 pb-12">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex justify-between gap-24">
            <div className="w-full sm:w-8/12">
              <div className="max-w-none font-verdana prose">
                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
              </div>
              <div className="my-12">
                <Form />
              </div>
            </div>
            <div className="w-full sm:w-4/12 pt-12 sm:pt-0">
              <iframe width="100%" height="100%" title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3198.71387390466!2d174.70051915116423!3d-36.705412979109255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d3b7aa98ff475%3A0x8ec3b1c9847e7ab4!2sSharp%20Sandblasting%20Services!5e0!3m2!1sen!2snz!4v1656476801234!5m2!1sen!2snz" allowFullScreen={false} loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </div>
      <GoogleReviews />
      <Footer />
    </div>
	)
}

export default ContactPage;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
        title
				page_title
				meta_description
				heading
        image {
					childImageSharp {
            gatsbyImageData(width: 1280)
          }
				}
			}
			fields {
				slug
			}
      html
		}
	}
`